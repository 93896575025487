<template>
  <div>
    <b-card-title title-tag="h2" class="mb-1">
      {{ $t("pages.forgotPassword.title") }} 🔒
    </b-card-title>

    <b-card-text class="mb-4">
      {{ $t("pages.forgotPassword.subtitle") }}
    </b-card-text>

    <validation-observer ref="forgotPasswordFormRules" #default="{invalid}">
      <b-form @submit.prevent="validateForm">
        <!-- email -->
        <b-form-group :label="$t('Email')" label-for="forgot-password-email">
          <validation-provider
            #default="{ errors }"
            :name="$t('Email')"
            rules="required|email"
          >
            <b-form-input
              id="forgot-password-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : null"
              name="forgot-password-email"
              autocomplete="email"
              tabindex="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          class="mt-5 d-flex align-items-center justify-content-center"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
          tabindex="2"
        >
          <b-spinner v-if="loading" small class="mr-2" />
          <span class="py-1">
            {{ $t("pages.forgotPassword.button") }}
          </span>
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-4">
      <b-link :to="{ name: 'foravila-login' }">
        <feather-icon icon="ChevronLeftIcon" class="mr-1" />
        {{ $t("pages.forgotPassword.goToLogin") }}
      </b-link>
    </p>
  </div>
</template>

<script>
import {
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@foravila-validations";
import { notifyError } from "@/utils/methods";
// import store from '@/store/index'

export default {
  components: {
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner
  },
  data() {
    return {
      loading: false,
      userEmail: null,
      required,
      email
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    emptyForm() {
      return !this.userEmail;
    },
    host() {
      return window.location.origin;
    }
  },
  watch: {
    currentLanguage() {
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    }
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage);
      if (!this.emptyForm) this.$refs.forgotPasswordFormRules.validate();
    },
    validateForm() {
      this.$refs.forgotPasswordFormRules.validate().then(success => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch("auth/requestPasswordReset", {
              email: this.userEmail,
              language: this.$i18n.locale,
              host: `${this.host}/reset-password`
            })
            .then(() => {
              this.$router.push({ name: "foravila-check-email" });
            })
            .catch(error => {
              this.$refs.forgotPasswordFormRules.setErrors(error);
              this.loading = false;
              notifyError(
                this.$t("errors.resetPassword.title"),
                this.$t("errors.resetPassword.text")
              );
            });
        }
      });
    }
  }
};
</script>
